<template>
  <div class="settings-wrapper">
    <Loading v-if="isLoading"></Loading>
    <div class="settings" v-else>
            <PFormLayout class="settings-form">


      <PBanner v-if="confirmCharge"
        title="Please Confirm your Plan"
        status="critical"
      >
        We need you to confirm your billing plan with Shopify. Thank you.

        <PButton v-if="plan === 'Basic'" @click='changePlan("Basic")' class="submit-form">Confirm Basic Plan</PButton>
        <PButton v-if="plan === 'Pro'" @click='changePlan("Pro")' class="submit-form">Confirm Pro Plan</PButton>
        <PButton v-if="plan === 'Enterprise'" @click='changePlan("Enterprise")' class="submit-form">Confirm Enterprise Plan</PButton>


      </PBanner>
      <PSubheading class="settings-header" element="h2">Plan</PSubheading>

  <PTextField
               class="input"
            label="Plan"
            v-model='plan'
            :labelHidden="false"
            :emptyLabel="false"
            disabled />
                  <PSubheading class="settings-header" element="h2">Plan Information</PSubheading>
 <PSubheading class="settings-header" element="h2">Basic</PSubheading>
        <PList >
    <PListItem>Monthly Price: $49</PListItem>
    <PListItem>Transaction fee per sale*: 7%</PListItem>
    <PListItem>Minimum fee per sale*: $1.00</PListItem>
    <PListItem>File Size per NFT: 5MB</PListItem>
    <PListItem>NFT Templates: 5</PListItem>
    <PListItem>Account Support: Yes</PListItem>
  </PList>
   <PSubheading class="settings-header" element="h2">Pro</PSubheading>
                <PList >
    <PListItem>Monthly Price: $399</PListItem>
    <PListItem>Transaction fee per sale*: 4%</PListItem>
    <PListItem>Minimum fee per sale*: $0.50</PListItem>
    <PListItem>File Size per NFT: 25MB</PListItem>
    <PListItem>NFT Templates: 25</PListItem>
    <PListItem>Account Support: Yes</PListItem>
  </PList>
     <PSubheading class="settings-header" element="h2">Enterprise</PSubheading>
      <PList>
    <PListItem>Monthly Price: $999</PListItem>
    <PListItem>Transaction fee per sale*: 2.25%</PListItem>
    <PListItem>Minimum fee per sale*: $0.25</PListItem>
    <PListItem>File Size per NFT: 100MB</PListItem>
    <PListItem>NFT Templates: Unlimited</PListItem>
    <PListItem>Account Support: Yes</PListItem>
  </PList>
       <p >Note* The transaction fee is a minimum fee or the % of the sale price</p>
       <div class="change-plans">
                         <p
                   v-if="nfts.length > 5 && plan !== 'Basic'"
      class="submit-form"
      >You have too many NFTs to switch to Basic. Please archive NFTs.</p>
                         <PButton
                   v-if="nfts.length < 6 && plan !== 'Basic'"
      @click='changePlan("Basic")'
      class="submit-form"
      >Change Plan To Basic</PButton>
                               <p
                   v-if="nfts.length > 25 && plan !== 'Pro'"
      class="submit-form"
      >You have too many NFTs to switch to Pro. Please archive NFTs.</p>
                         <PButton
                   v-if="nfts.length < 26 && plan !== 'Pro'"
      @click='changePlan("Pro")'
      class="submit-form"
      >Change Plan To Pro</PButton>
                         <PButton
                               v-if="plan !== 'Enterprise'"
      @click='changePlan("Enterprise")'
      class="submit-form"
      >Change Plan To Enterprise</PButton>
      </div>
            </PFormLayout>
      <PSubheading class="settings-header" element="h2">Venly Settings</PSubheading>
      <p><b>If you need to create an Venly account please do so <PLink rel="noreferrer noopener" target="_blank" url="https://forms.venly.io/shopify-id" href="https://forms.venly.io/shopify-id">here.</PLink></b></p>
                          <input
        @change=fileUploadImage
        id="settingsImageUpload"
        type="file"
             class="input"
            accept="image/*"
            label="Image"
            :labelHidden="false"
            :emptyLabel="false"
              />
            <input
          @change=fileUploadMedia
        id="settingsMediaUpload"
        type="file"
             class="input"
            accept="image/*"
            label="media"
            :labelHidden="false"
            :emptyLabel="false"
              />
      <PFormLayout v-if="billingActive" class="settings-form">
        <PSubheading class="label" element="h2">Venly Client Id</PSubheading>
                  <PTextField
               class="input"
            label="Venly Client Id"
            v-model='client_id'
            :labelHidden="false"
            :emptyLabel="false" />
                    <PSubheading class="label" element="h2">Venly Client Secret</PSubheading>

                              <PTextField
               class="input"
            label="Venly Client Secret"
            v-model='client_secret'
            :labelHidden="false"
            :emptyLabel="false" />
                    <PSubheading class="label" element="h2">Venly App Id</PSubheading>

                              <PTextField
               class="input"
            label="Venly App Id"
            v-model='app_id'
            :labelHidden="false"
            :emptyLabel="false" />
          <PSubheading class="settings-header" element="h2">Brand Settings and Information</PSubheading>
          <PSubheading class="label" element="h2">Name</PSubheading>

                              <PTextField
               class="input"
            label="Name"
            v-model='name'
            :labelHidden="false"
            :emptyLabel="false" />
          <PSubheading class="label" element="h2">Description</PSubheading>

                              <PTextField
               class="input"
            label="Description"
            v-model='description'
            :labelHidden="false"
            :emptyLabel="false"
                        :minHeight="100"
            :multiline="true" />
                      <PSubheading class="label" element="h2">Symbol</PSubheading>

           <PTextField
               class="input"
            label="Symbol"
            v-model='symbol'
            :labelHidden="false"
            :emptyLabel="false" />
                                  <PSubheading class="label" element="h2">External URL</PSubheading>

           <PTextField
               class="input"
            label="External Url"
            v-model='externalUrl'
            :labelHidden="false"
            :emptyLabel="false" />
                                              <PSubheading class="label" element="h2">Project Logo (144x144)</PSubheading>
                                                      <div @click=imageUpload id="settingsImageUploadButton">
          <PSubheading class="label" element="h2">Click to upload an image</PSubheading>
        </div>

        <img class="settings-img" v-if="this.image !== '' && this.settingsExist" :src="`${image}`" />

          <PSubheading class="label" element="h2">Project Banner (900x600)</PSubheading>
                  <div @click=mediaUpload id="settingsMediaUploadButton">
          <PSubheading class="label" element="h2">Click to upload media</PSubheading>
        </div>
            <img class="settings-img" v-if="this.media !== '' && this.settingsExist" :src="`${media}`" />
                 <PSubheading v-if="!isLoading" class="install-header" element="h1">NFT FULFILLMENT</PSubheading>
  <PStack vertical="" v-if="!isLoading">
    <PStackItem>
      <PRadioButton
      v-if="fulfillmentMethod === 'Auto'"
        id="Auto"
        label="Auto"
        helpText="Select this to have orders automatically approved and sent to be minted."
        name="Auto"
        checked
        v-model="fulfillmentMethod"
      />
      <PRadioButton
            v-else
        id="Auto"
        label="Auto"
        helpText="Select this to have orders automatically approved and sent to be minted."
        name="Auto"
        v-model="plan"
        :checked=false
      @change="changeFulfillment('Auto')"
      />
            <PRadioButton
      v-if="fulfillmentMethod === 'Manual'"
        id="Manual"
        label="Manual"
        helpText="Select this to to approve all orders to be minted."
        name="Manual"
        checked
        v-model="fulfillmentMethod"
      />
      <PRadioButton
            v-else
        id="Manual"
        label="Manual"
        helpText="Select this to to approve all orders to be minted."
        name="Manual"
        v-model="plan"
        :checked=false
      @change="changeFulfillment('Manual')"
      />
    </PStackItem>
  </PStack>
      </PFormLayout>
      <p class="error" v-if="errors.length > 0">{{ errors }}</p>
      <PButton v-if="billingActive"
      @click='submit'
      class="submit-form"
      >Submit</PButton>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import Loading from '../components/Loading.vue';
import axios from 'axios';
import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';

export default {
  name: 'Settings',
  props: ['site', 'update', 'plan'],
  components: {
    Loading
  },
  data(){
    return {
     isLoading: true,
     confirmCharge: false,
     title: "Settings",
     client_id: '',
     client_secret: '',
     app_id: '',
     errors: '',
     isOk: false,
     alert: false,
     timeout: 2500,
     name: '',
     description: '',
     chain: process.env.VUE_APP_SECRET_TYPE,
     symbol: '',
     image: '',
     externalUrl: '',
     media: '',
     apiUrl: process.env.VUE_APP_BACKEND,
     apiKey: process.env.VUE_APP_SHOPIFY_API_KEY,
     overlay: false,
     disable: false,
     settingsExist: false,
     fulfillmentMethod: 'Manual',
     nfts: [],
     archived: false,
     billingActive: false,
     shopifyDemoStore: process.env.VUE_APP_SHOPIFY_DEMO_STORE
    }
  },
  methods: {
    changeFulfillment(fulfillment){
      this.fulfillmentMethod = fulfillment;
    },
    async changePlan(plan){
        this.isLoading = true;
        let self = this;
        //window.location = `${this.apiUrl}plans/${this.site}/${plan}`
        await axios({
        method:'post',
        url: `${this.apiUrl}plans/${this.site}/${plan}`
        }).then(function(response){

            var redirectURL = response.data
            //console.log(redirectURL)
            //window.open(redirectURL, '_blank').focus();

            //window.location.assign(redirectURL);
            //'GO REDIRECT')
            //console.log(window)
            if (window.top === window.self) {
              //console.log('go window location')
              window.location.assign(redirectURL);
            } else {
              //console.log('go app bridge redirect')
              window.top.location.href = redirectURL
              const app = createApp({
                apiKey: self.apiKey,
                host: self.site
              });
              Redirect.create(app).dispatch(Redirect.Action.REMOTE, redirectURL);
            }

            //console.log('go window location href')
            window.top.location.href = redirectURL


            self.isLoading = false;
        }).catch(function() {
        });
    },
    fileUploadImage(){
      let file = document.getElementById("settingsImageUpload").files[0] || this.image
      this.image = URL.createObjectURL(file)
    },
      fileUploadMedia(){
       let file = document.getElementById("settingsMediaUpload").files[0] || this.media
       this.media = URL.createObjectURL(file)
    },
    imageUpload(){
      let el = document.getElementById("settingsImageUpload");
      el.click();
    },
    mediaUpload(){
      let el = document.getElementById("settingsMediaUpload");
      el.click();
    },
    checkSubmit(){
      this.errors = '';
      if(this.client_id !== '' && this.client_secret !== '' && this.app_id !== '' &&
        this.name !== '' && this.description !== '' && this.chain !== '' && this.symbol !== '' &&
        this.image !== '' && this.externalUrl !== '' && this.media !== ''  && this.media !== undefined && this.image !== undefined){
        this.isOk = true;
      }
      else{
        this.errors = 'Invalid data. Please try again.';
      }
    },
    async submit(){
      this.image = document.getElementById("settingsImageUpload").files[0] || this.image;
      this.media = document.getElementById("settingsMediaUpload").files[0] || this.media;
      this.checkSubmit();
      if(this.isOk){
        this.isLoading = true;
        this.overlay = true;
        let storageRef = firebase.storage().ref();
        let imageRef = storageRef.child(`/settings/${this.client_id}_image_${this.image.name}`);
        let mediaRef = storageRef.child(`/settings/${this.client_id}_media_${this.media.name}`);

        await imageRef.put(this.image).then(async function() {
        });
        await mediaRef.put(this.media).then(async function() {
        });

        this.image = `https://storage.googleapis.com/${process.env.VUE_APP_FIREBASE_STORAGE_BUCKET}/settings/${this.client_id}_image_${this.image.name}`;
        this.media = `https://storage.googleapis.com/${process.env.VUE_APP_FIREBASE_STORAGE_BUCKET}/settings/${this.client_id}_media_${this.media.name}`;

        let settings = {
          client_id: this.client_id,
          client_secret: this.client_secret,
          app_id: this.app_id,
          name: this.name,
          description: this.description,
          chain: this.chain,
          symbol: this.symbol,
          image: this.image,
          externalUrl: this.externalUrl,
          media: this.media,
          site: this.site,
          fulfillmentMethod: this.fulfillmentMethod,
          archived: this.archived
        }

        await axios({
          method:'post',
          url: `${this.apiUrl}shop/settings`,
          data: settings
        })

        await firebase.firestore().collection('sites').doc(this.site).update(settings);
        this.update();
        this.disable = false;
        this.overlay = false;
        this.$pToast.open({
            message: 'Settings Updated!'
        });
        this.isLoading = false;
      }
    }
  },
  mounted: async function () {
    //console.log("site: "+this.site)
    console.log("[ GO ] settings")
    let siteRef = await firebase.firestore().collection('sites').doc(this.site).get()
    console.log("[ GO ] NFTS")
    let nftsRef = await firebase.firestore().collection('nfts').where("site", "==", this.site).get()
    if(nftsRef.docs[0]){
      for(let nft of nftsRef.docs) {
       let nftx = nft.data();
        if(nftx.nft.archived == false){
          this.nfts.push(nft)
        }
      }
    }
    if(siteRef.exists){
      let { billing, client_id, client_secret, app_id, name, description, externalUrl, image, chain, symbol, media, fulfillmentMethod, archived } = siteRef.data();
      if(client_id){
        this.client_id = client_id;
      }
      if(client_secret){
        this.client_secret = client_secret;
      }
      if(app_id){
        this.app_id = app_id;
      }
      if(name){
        this.name = name;
      }
      if(description){
        this.description = description;
      }
      if(externalUrl){
        this.externalUrl = externalUrl;
      }
      if(image){
        this.image = image;
      }
      if(chain){
        this.chain = chain;
      }
      if(symbol){
        this.symbol = symbol;
      }
      if(media){
        this.media = media;
      }
      if(fulfillmentMethod){
        this.fulfillmentMethod = fulfillmentMethod;
      }
      if(archived){
        this.archived = archived;
      }

      if(billing){
        if(billing.recurring_application_charge.test !== undefined){
          if(billing.recurring_application_charge.test === true && this.site !== this.shopifyDemoStore){
            this.confirmCharge = true
          }
        }
        if(billing.recurring_application_charge.status === 'active'){
          this.billingActive = true
        }else{
          this.confirmCharge = true
        }
      }


      this.settingsExist = true;
    }
    else{
      this.$router.push({ path: `/` })
    }
    this.isLoading = false
  }
}
</script>

<style scoped>
.settings-header {
  text-align: center;
}
  .input {
    margin-top: -10px !important;
  }
  .error {
    color: red;
    margin-top: 10px;
  }
.settings-form {
  margin: 0 auto;
  max-width: 75%;
  text-align: left;
}
.settings-form input {
  margin-bottom: 10px;
  padding: 5px 10px;
}
.submit-form {
  cursor: pointer;
}
.settings-form {
  padding: 20px 0px;
}
.settings-form .v-input {
  width: 50%;
}
.settings-title {
  margin-bottom: 20px;
}
.settings-img {
  margin: 10px;
  max-width: 200px;
}
@media screen and (max-width: 768px){
  .settings-form {
    max-width: 95%;
  }
  .settings-img {
  max-width: 200px;
}
}

  #settingsImageUpload {
    visibility: hidden;
    width: 1px;
  }
  #settingsImageUploadButton {
    width: 100%;
    min-height: 50px;
    border: 1px solid #babfc3;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #settingsMediaUpload {
    visibility: hidden;
    width: 1px;
  }
  #settingsMediaUploadButton {
    width: 100%;
    min-height: 50px;
    border: 1px solid #babfc3;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .change-plans button {
    margin-right: 20px;
  }
  .change-plans p {
    margin-bottom: 20px;
  }
  .settings-wrapper {
    margin-bottom: 40px;
  }
</style>
