<template>
  <div id="app">
    <Header :site="site" :installed="installed" :settings="settings" :session="session"></Header>
    <div id="mainContent">
    <Loading v-if="isLoading"></Loading>
    <router-view :plan="plan" :update="update" :site="site" :installed="installed" :settings="settings" :session="session" v-else></router-view>
    </div>
    <Footer :site="site" :installed="installed" :settings="settings" :session="session"></Footer>
  </div>
</template>

<script>
import firebase from 'firebase'
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Loading from './components/Loading.vue';
import axios from 'axios';

export default {
  name: 'App',
    data(){
    return {
     site: '',
     installed: false,
     isLoading: true,
     settings: false,
     session: '',
     plan: '',
     apiUrl: process.env.VUE_APP_BACKEND,
    }
  },
  mounted: async function () {
    this.site = this.$route.query.shop;
    this.site = 'seed-venly-test.myshopify.com'
    // let sitex = window.location.href;
    // let sitexSplit = sitex.split('://')
    // let sitexNew = sitexSplit[1].split('.com')
    //console.log(sitexNew[0])
    if(this.site !== ''){
    console.log(`[ GET ] site from firestore ${this.site}`)
    let siteRef = await firebase.firestore().collection('sites').doc(this.site).get()
    await firebase.auth().signInAnonymously().then(() => {
      firebase.auth().currentUser;
    }).catch(() => {
      // An error ocurred
      // ...
    });

      if(siteRef.exists){
        console.log(`[ CHECK ] site exists`)
        let { client_id, client_secret, app_id, contractId, plan, newPlan, billing, archived } = siteRef.data();
        if(!archived){
          this.installed = true;
        }

        if(!client_id || !client_secret || !app_id || !contractId){
          console.log(`[ CHECK ] no settings`)
          this.settings = false;
        }else{
          let self = this;
          console.log(`[ AUTH ] site`)
          await firebase.auth().signInAnonymously().then(() => {
            // User signed in.
            //const user = firebase.auth().currentUser;
            console.log('[ AUTH] user info ')
            //console.log(user)
            self.settings = true;
          }).catch((error) => {
            // An error ocurred
            // ...
            console.log(`[ AUTH ] error`)
            console.log(error)
          });
          this.settings = self.settings
        }
        console.log(`[ CHECK ] billing`)
        if(billing){

          console.log(`[ CHECK ] billing 1`)
          if(billing.recurring_application_charge){

              console.log(`[ CHECK ] billing 2`)
              if(billing.recurring_application_charge.status !== 'active'){
                let shopifyPlan = '';

                console.log(`[ CHECK ] billing 3`)
                await axios({
                  method:'post',
                  url: `${this.apiUrl}plans/${this.site}`
                  }).then(function(response){
                    shopifyPlan = response.data;
                }).catch(function(error) {
                  console.log(error)
                });

                console.log(`[ CHECK ] billing 4`)
                if(shopifyPlan && shopifyPlan.recurring_application_charge && shopifyPlan.recurring_application_charge.status === 'active'){
                  let updateObj = {
                    billing: shopifyPlan
                  }
                  console.log(`[ CHECK ] billing 5`)
                  console.log(`[ UPDATE ] site billing plan`)
                  //console.log(updateObj)
                  
                  await firebase.firestore().collection('sites').doc(this.site).update(updateObj);
                  this.plan = newPlan;
                }else{
                  console.log(`[ CHECK ] billing 6`)
                  this.plan = plan;
                }

              }else{
                console.log(`[ CHECK ] billing 7`)
                this.plan = newPlan;
              }
          }else{
            console.log(`[ CHECK ] billing 8`)
            this.settings = false;
            this.installed = false;
          }
        }else{
            console.log(`[ CHECK ] billing 9`)
            this.settings = false;
            this.installed = false;
        }
      }
    }else{
      console.log(`[ CHECK ] billing 10`)
      this.settings = false;
      this.site = false;
    }
    const session   = this.$route.query.session;
    const code      = this.$route.query.code;
    const charge_id = this.$route.query.charge_id;
    const install = this.$route.query.install;
    this.session = session;
    //console.log(this.site, session, install)

    console.log(`[ GO ] routes...`)
    if(this.site && (!this.installed || install == 'true')){
       console.log('[ ROUTE ] - install')
      this.$router.push({ path: `/install`, query: { shop: this.site, code: code, charge_id: charge_id, install: install } })
    }
    else if(this.site && this.installed && session && !this.settings){
      console.log('[ ROUTE ] - settings')
      this.$router.push({ path: `/settings`, query: { shop: this.site } })
    }
    else if(this.site && this.installed && session && this.settings){
      console.log('[ ROUTE ] - home')
      this.$router.push({ path: `/home`, query: { shop: this.site } })
    }
    else if(charge_id && this.site && !session){
     //   location.assign(`https://${this.site}/admin/apps/arkane-networks`)
            console.log('[ ROUTE ] - login 1')
           this.$router.push({ path: `/login` })
    }
    else{
      console.log('[ ROUTE ] - login 2')
      this.$router.push({ path: `/login` })
    }
    this.isLoading = false;
  },
  methods: { 
    update: function () {
        this.settings = true;
    }
  },
  components: {
    Header,
    Footer,
    Loading
  }
}
</script>

<style>

.Polaris-Modal-CloseButton{
  display: none !important;
}

body{
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}
#mainContent {
  padding: 20px;
  min-height: 400px;
}

.Polaris-FormLayout__Item {
  text-align: left;
}
.Polaris-Tabs__Tab--selected span::before,
.Polaris-Tabs__Tab--selected span::after {
  width: 0;
}
.router-link-exact-active.Polaris-Tabs__Tab--selected span::before,
.router-link-exact-active.Polaris-Tabs__Tab--selected span::after {
  width: 100%;
}
  .Polaris-Filters-ConnectedFilterControl__RightContainer {
    display: none !important;
  }
  tr.Polaris-DataTable__TableRow:nth-child(odd) {
    background:#fff;
  }

  .Polaris-DataTable__Table {
      border: 5px solid #c4cdd5;
      border-radius: 5px;
  }
</style>
